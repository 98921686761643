import React, { useState, useRef, useEffect } from "react";

import { Toast } from 'react-bootstrap';
export default function ToastBox(props) {

    const [show, setShow] = useState(true);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Close toast if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
            props.setShow(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

    return (
        <div className="toast-wrap">
            <Toast onClose={() => props.setShow(false)} show={props.show} 
        ref={wrapperRef}>
                <Toast.Header>
                    <strong className="mr-auto">{props.Type}</strong>
                </Toast.Header>
                <Toast.Body>{props.Text}</Toast.Body>
            </Toast>
        </div>
    )
}